<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row class="m-1">
            <b-col cols="12">
                <b-form-group label="Ingresar un comentario" label-for="add-comments">
                    <b-input-group class="input-group-merge">
                        <b-form-input v-model="comment" size="sm" id="add-comments" placeholder="Describe un breve comentario" autocomplete="off" autofocus type="text" trim />
                        <b-input-group-append v-if="comment != ''">
                            <b-button @click="store" size="sm" variant="outline-primary">
                                <feather-icon icon="SaveIcon" />
                                Guardar
                            </b-button>
                            <b-button size="sm" @click="comment = ''" variant="outline-danger">
                                <feather-icon icon="XIcon" />
                                Limpiar
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-table class="position-relative b-table-responsive mb-0 text-small small"
            :items="comments" responsive :fields="[
                { key: 'usuario_Nombre', label: 'Creado Por' },
                { key: 'comentarios', label: 'Comentario' },
                { key: 'fecha_Alta', label: 'Fecha Registro' },
            ]"
            show-empty empty-text="No se encontraron resultados" :busy.sync="loading" small>
            <template slot="empty">
                <h6 class="text-center mt-1">
                    No se encontraron registros
                </h6>
            </template>

            <template #cell(fecha_Alta)="data">
                <span class="text-capitalize">{{ formatDate(data.item.fecha_Alta) }}</span>
            </template>
        </b-table>  
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import EventBus from "@/event-bus"
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex';
import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import AuthorizationModal from '@/@core/components/AuthorizationModal.vue'
import SearchByCustomer from "@core/components/invoices/SearchByCustomer.vue"
import moment from 'moment';
import { nextTick } from '@vue/composition-api';

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

import DoctosPVProvider from "@/providers/DoctosPV"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';
const DoctosPVResource = new DoctosPVProvider()

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'

const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: 'SearchInvoices',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        FeatherIcon,
        BTabs,
        BTab,

        //
        ArticlesInvoice,
        AuthorizationModal,
        SearchByCustomer,
        vSelect,
    },
    mixins: [heightTransition],
    props: {
        doctoPvId: {
            type: Number,
            required: true,
        },        
    },
    data() {
        return {
            loading: false,
            comment: '',
            comments: [],
           
        }
    },
    created () {},
    computed: {
        
    },
    async mounted() {
        await this.getCommentsByDoctoPvId()
    },
    beforeDestroy() {},
    methods: {
        async getCommentsByDoctoPvId() {
            this.loading = true
            const { data } = await DeliveryRoutesResource.getCommentsByDoctoPvId(this.doctoPvId)
            this.loading = false

            if (data.isSuccesful) {
                this.comments = data.data
            }
        },
        async store () {
            let folioTickets = null;

            //Si existe el comentarios desde invoiceData.centralRutasEntregasObservaciones necesito obtener el Folio_Tickets
            if (this.comments.length > 0) {

                let tickets = this.comments;
                let lastFolioTicket = tickets[tickets.length - 1].folios_Tickets;

                //Obtengo la ultima posicion
                folioTickets = lastFolioTicket                
            } else {
                folioTickets = `${this.doctoPvId}`
            }

            try {
                this.loading = true
                const payload = {
                    id: 0,
                    usuario_Id: "string",
                    usuario_Nombre: "string",
                    comentarios: this.comment,
                    folios_Tickets: folioTickets,
                    fecha_Alta: "2024-07-13T01:07:02.940Z"
                }
                const { data } = await DeliveryRoutesResource.storeComments(payload)
                this.loading = false
                if (data.isSuccesful) {
                    
                    //Ahora desde invoiceData.centralRutasEntregasObservaciones necesito agregar el nuevo comentario al inicio
                    this.comments.unshift(data.data)
                    this.comment = ''

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
    },
    watch: {}    
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>