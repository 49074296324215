<template>
    <b-overlay :show="loading" blur="3px" variant="transparent" rounded="lg" opacity="0.90">

        <template #overlay>
            <div class="text-center">
                <feather-icon icon="RefreshCwIcon" class="font-large-1" animation="rotate"/>
                <h5>Por favor espere...</h5>
            </div>
        </template>

        <!-- Info Detail -->
        <b-card v-if="routing != undefined && !isAll" no-body class="border-primary">
            <b-card-body>
                <b-card-title v-if="routing.tipo_Docto_Real == 'F'">Detalle Factura {{ routing.docto.folio }}
                  <span v-if="routing.estatus != 'SALIDA TICKET' && !routing.cliente_Pasa">En Ruta</span>
                </b-card-title>
                <b-card-title v-else>Detalle Ticket {{ routing.folio }}</b-card-title>

                <b-card-sub-title style="color: black!important;">
                    <p>Importe: {{ formatCurrency(routing.importe_Neto) }} Saldo Fact. {{ formatCurrency(routing.docto.doctosPVSaldo[0].saldo_Cargo) }}</p>
                </b-card-sub-title>
                
                
                <!-- Datos factura -->
                <b-card-sub-title>
                    <strong>Vendedor:</strong> <span class="text-capitalize mr-2">{{ routing.nombre_Vendedor }}</span>
                    <strong>Fecha:</strong> <span class="text-capitalize mr-2">{{ formatDate(routing.fecha_Alta) }}</span><br>
                    <strong v-if="routing.estatus != 'SALIDA TICKET' && routing.tipo_Docto === 'F'">Estatus: </strong> 
                    <b-badge v-if="routing.estatus != 'SALIDA TICKET' && routing.tipo_Docto === 'F'" pill variant="light-primary">{{ routing.estatus }}</b-badge>
                    <b-badge pill :variant="diasPlazoVariant"><small>{{ diasPlazoLabel }}</small></b-badge>
                </b-card-sub-title>

                <!-- Datos del chofer -->
                <b-card-text class="mt-1">
                    <small><strong>Chofer:</strong> {{ routing.webOperadores ? `${routing.webOperadores.nombre} ${routing.webOperadores.apellidos}`  : '' }}</small><br>
                    <small><strong>Unidad/Camión:</strong> {{ routing.webCamiones ? `${routing.webCamiones.identificador} - ${routing.webCamiones.placas}`  : '' }}</small><br>
                </b-card-text>

                <!-- Datos de entrega -->
                <small><span class="font-weight-bold">Dirección Entrega</span></small><br> 
                <small>{{ routing.calle }} {{ routing.num_Exterior }} {{ routing.colonia }}</small>
                <table>
                    <tr>
                        <td>
                            <small class="font-weight-bold">Teléfono:</small>
                            <small class="text-wrap"> {{ routing.telefono1 || ' -- ' }}</small>
                        </td>
                        <td></td>
                        <td>
                            <small class="font-weight-bold">Contacto:</small> 
                            <small> {{ routing.direccion_Contacto || ' -- '}}</small>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <small class="font-weight-bold">Referencia:</small>
                            <small> {{ routing.direccion_Referencia || ' -- ' }}</small>
                        </td>
                        <td></td>
                        <td>
                            <small class="font-weight-bold">Autor:</small>
                            <small> {{ routing.usuario_Nombre || ' -- ' }}</small>
                        </td>
                        <td></td>
                    </tr>                        
                </table>                
                <b-card-text>
                    <small><strong>Comentarios de Ruta:</strong> {{ routing.observaciones }}</small>
                </b-card-text>  
                
                <!-- Acciones permitidas -->
                <div v-if="!isAuditor && !isAdministrative && !isAutorizator">
                    <b-card-text v-if="(routing.estatus === 'EN RUTA' && routing.estatus != 'CLIENTE PASA' && !isRouting) && routing.docto.estatus !== 'C'">
                        <div class="demo-inline-spacing _text-nowrap">
                            <b-button :disabled="loading || routing.docto.estatus === 'C' || showDetail" @click="isActiveModalChofer = true" size="sm" variant="warning"
                            :block="isMobile">
                                <feather-icon icon="TruckIcon" />
                                Cambiar Chofer
                            </b-button>
                        </div>
                    </b-card-text>

                    <b-card-text v-if="routing && routing.docto && routing.docto.estatus.trim() !== 'C' && canClosed && !isViewRouting || routing.estatus == 'CLIENTE PASA'">
                        <b-button :disabled="showDetail" v-if="routing.estatus == 'CLIENTE PASA'" @click="closingInvoice" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
                            <feather-icon icon="XIcon" /> Cerrar Factura
                        </b-button>

                        <b-button v-else @click="closingInvoice" :disabled="!canClosed" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
                            <feather-icon icon="XIcon" /> Cerrar Factura
                        </b-button>
                    </b-card-text>

                    <b-card-text v-if="(routing.estatus == 'EN RUTA' || routing.estatus == 'CERRADA' || routing.estatus == 'CLIENTE PASA') && routing.docto.estatus !== 'C'">                        
                        <delivery-document
                            v-if="hasRouting && routing.estatus == 'CLIENTE PASA' && routing.docto" :doctoPvId="routing.docto_Pv_Id" :routing="routing" 
                            @pdf-progress="handlePdfProgress"/>
                        
                        <delivery-document
                            v-else-if="hasRouting && routing.fecha_Asignacion_Chofer && routing.docto" :doctoPvId="routing.docto_Pv_Id" :routing="routing" 
                            @pdf-progress="handlePdfProgress"/>
                    </b-card-text>
                </div>

                <!-- Alert by invoice when cancelled -->
                <b-card-text v-if="routing && routing.docto && routing.docto.estatus === 'C'">
                    <div class="demo-spacing-0">
                        <b-alert variant="danger" show>
                            <div class="alert-body">
                                <feather-icon class="mr-25" icon="AlertTriangleIcon"/>
                                <span><strong>NOTA:</strong> El documento esta cancelado</span>
                            </div>
                        </b-alert>
                    </div>
                </b-card-text>
            </b-card-body>
        </b-card>

        <!-- Listado de Enrutamientos -->
        <b-card v-else-if="isAll && !isOutput" no-body>
            <b-card-header>
                <b-card-title>Lista de Enrutamientos</b-card-title>
                <b-card-sub-title v-if="activeTab || isActiveSearch">
                    <b-form-checkbox v-model="isPrinted">No Impresos <feather-icon id="popover-button-help" size="18" icon="HelpCircleIcon" @click="hidePopover" /></b-form-checkbox> 
                    <b-form-checkbox v-model="isRoutingSearch">Solo En Ruta</b-form-checkbox>

                    <!-- Popover Help  -->
                    <b-popover target="popover-button-help" placement="top" triggers="hover" variant="info">
                        <template #title>
                            Información
                        </template>
                        <p><strong>No Impresos:</strong> Filtra las rutas que aún no han impreso la hoja de control.</p>
                        <p><strong>Solo Rutas:</strong> Filtra las rutas que están actualmente en estatus "En Ruta".</p>
                    </b-popover>
                </b-card-sub-title>
                    
                <b-input-group>
                    <b-form-input v-model="searchText" @keydown.passive.enter="debouncedSearch" size="sm" placeholder="Buscar por factura, cliente o chofer" />
                    <b-input-group-append>
                    <b-button @click="debouncedSearch" size="sm" variant="outline-primary">
                        <feather-icon icon="SearchIcon"/>
                    </b-button>
                    <b-button v-if="searchText" @click="clearSearchItems" size="sm" variant="outline-danger">
                        <feather-icon icon="XIcon"/>
                    </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-card-header>

            <vue-perfect-scrollbar 
                v-if="searchText && isActiveSearch"
                ref="scrollContainerSearch"
                :settings="perfectScrollbarSettings" 
                class="scrollable-container media-list scroll-area"
                @ps-y-reach-end="scrollHandle">
                <b-list-group>
                    <b-list-group-item v-for="(item, index) in searchVisibleItems" :key="index" class="d-flex justify-content-between align-items-center">
                        <strong>
                            <b-link @click="copyToClipboard(item)" class="text-dark">Factura {{ item.folio }} <feather-icon icon="CopyIcon" /></b-link> <br>
                            <small><feather-icon icon="UserIcon"/> {{ item.nombre_Cliente }}</small><br>
                            <small v-if="item.webOperadores"><feather-icon icon="TruckIcon"/> {{ item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : '' }}<br></small>
                            <small class="text-capitalize"><feather-icon icon="CalendarIcon"/> {{ formatDate(item.fecha_Alta) }}</small>
                            <b-badge v-if="item.entrega_Inmediata" variant="light-danger" pill>
                                Entrega Inmediata
                            </b-badge><br>
                            <b-link @click="selectItem(item)">
                                <small>Ver Detalle</small>
                            </b-link>
                        </strong>
                        <table>
                            <tr>
                                <td>
                                    <b-badge variant="primary" pill>
                                        {{ item.estatus }}
                                    </b-badge>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b-badge pill :variant="diasPlazoVariantByItem(item)"><small>{{ diasPlazoLabelByItem(item) }}</small></b-badge>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                </td>
                            </tr>
                            <tr v-if="item.estatus == 'EN RUTA' || item.estatus == 'CLIENTE PASA' || item.estatus == 'CERRADA'">
                                <td>
                                    <b-badge pill variant="dark"><small>Impreso {{ item.impreso ? 'SI' : 'NO' }}</small></b-badge>
                                </td>
                            </tr>
                        </table>                                
                    </b-list-group-item>
                </b-list-group>
                <div v-if="loading" class="text-center">Cargando más...</div>
            </vue-perfect-scrollbar>

            <b-tabs v-else fill v-model="activeTab" class="email-user-list scroll-area">
                <b-tab @click="changeActiveTab('ABIERTA')">
                    <template #title>
                        <span>ABIERTAS <b-badge pill variant="danger">{{ totalOpening }}</b-badge></span>
                    </template>

                    <vue-perfect-scrollbar 
                        v-if="activeTab == 0"
                        ref="scrollContainer"
                        :settings="perfectScrollbarSettings" 
                        class="scrollable-container media-list scroll-area"
                        @ps-y-reach-end="getDeliveryRountingByUser('ABIERTA')">
                        <b-list-group>
                            <b-list-group-item v-for="(item, index) in visibleItems" :key="index" class="d-flex justify-content-between align-items-center">
                                <strong>
                                    <b-link @click="copyToClipboard(item)" class="text-dark">Factura {{ item.folio }} <feather-icon icon="MessageCircleIcon" /></b-link> <br>
                                    <small><feather-icon icon="UserIcon"/> {{ item.nombre_Cliente }}</small><br>
                                    <small v-if="item.webOperadores"><feather-icon icon="TruckIcon"/> {{ item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : '' }}<br></small>
                                    <small class="text-capitalize"><feather-icon icon="CalendarIcon"/> {{ formatDate(item.fecha_Alta) }}</small>
                                    <b-badge v-if="item.entrega_Inmediata" variant="light-danger" pill>
                                        Entrega Inmediata
                                    </b-badge><br>
                                    <b-link @click="selectItem(item)">
                                        <small>Ver Detalle</small>
                                    </b-link>
                                </strong>
                                <table>
                                    <tr>
                                        <td>
                                            <b-badge variant="primary" pill>
                                                {{ item.estatus }}
                                            </b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="diasPlazoVariantByItem(item)"><small>{{ diasPlazoLabelByItem(item) }}</small></b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                        </td>
                                    </tr>
                                </table>                                
                            </b-list-group-item>
                        </b-list-group>

                        <div v-if="loading" class="text-center">Cargando más...</div>

                    </vue-perfect-scrollbar>
                </b-tab>

                <b-tab @click="changeActiveTab('EN RUTA')">
                    <template #title>
                        <span>EN RUTA <b-badge pill variant="danger">{{ totalInRoute }}</b-badge></span>
                    </template>
                    <vue-perfect-scrollbar 
                        v-if="activeTab == 1"
                        ref="scrollContainerInRouting"
                        :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
                        @ps-y-reach-end="getDeliveryRountingByUserAndStatus('EN RUTA')"
                        >
                        <b-list-group>
                            <b-list-group-item v-for="(item, index) in routingVisibleItems" :key="index" class="d-flex justify-content-between align-items-center">
                                <strong>
                                    <b-link @click="copyToClipboard(item)" class="text-dark">Factura {{ item.folio }} <feather-icon icon="CopyIcon" /></b-link> <br>
                                    <small><feather-icon icon="UserIcon"/> {{ item.nombre_Cliente }}</small><br>
                                    <small v-if="item.webOperadores"><feather-icon icon="TruckIcon"/> {{ item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : '' }}<br></small>
                                    <small class="text-capitalize"><feather-icon icon="CalendarIcon"/> {{ formatDate(item.fecha_Alta) }}</small>
                                    <b-badge v-if="item.entrega_Inmediata" variant="light-danger" pill>
                                        Entrega Inmediata
                                    </b-badge><br>
                                    <b-link @click="selectItem(item)">
                                        <small>Ver Detalle</small>
                                    </b-link>
                                </strong>
                                <table>
                                    <tr>
                                        <td>
                                            <b-badge variant="primary" pill>{{ item.estatus }}</b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="diasPlazoVariantByItem(item)"><small>{{ diasPlazoLabelByItem(item) }}</small></b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <b-badge pill variant="dark"><small>Impreso {{ item.impreso ? 'SI' : 'NO' }}</small></b-badge>
                                        </td>
                                    </tr>
                                </table>  
                            </b-list-group-item>
                        </b-list-group>

                        <div v-if="loading" class="text-center">Cargando más...</div>
                    </vue-perfect-scrollbar>
                </b-tab>

                <b-tab @click="changeActiveTab('CLIENTE PASA')">
                    <template #title>
                        <span>CLIENTE PASA <b-badge pill variant="danger">{{ totalCustomerPassBy }}</b-badge></span>
                    </template>
                    <vue-perfect-scrollbar 
                        v-if="activeTab == 2"
                        ref="scrollContainerPassByRouting"
                        :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
                        @ps-y-reach-end="getDeliveryRountingByUserAndPassBy('CLIENTE PASA')"
                        >
                        <b-list-group>
                            <b-list-group-item v-for="(item, index) in passByVisibleItems" :key="index" class="d-flex justify-content-between align-items-center">
                                <strong>
                                    <b-link @click="copyToClipboard(item)" class="text-dark">Factura {{ item.folio }} <feather-icon icon="CopyIcon" /></b-link> <br>
                                    <small><feather-icon icon="UserIcon"/> {{ item.nombre_Cliente }}</small><br>
                                    <small v-if="item.webOperadores"><feather-icon icon="TruckIcon"/> {{ item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : '' }}<br></small>
                                    <small class="text-capitalize"><feather-icon icon="CalendarIcon"/> {{ formatDate(item.fecha_Alta) }}</small>
                                    <b-badge v-if="item.entrega_Inmediata" variant="light-danger" pill>
                                        Entrega Inmediata
                                    </b-badge><br>
                                    <b-link @click="selectItem(item)">
                                        <small>Ver Detalle</small>
                                    </b-link>
                                </strong>
                                <table>
                                    <tr>
                                        <td>
                                            <b-badge variant="primary" pill>{{ item.estatus }}</b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="diasPlazoVariantByItem(item)"><small>{{ diasPlazoLabelByItem(item) }}</small></b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                           <b-badge pill variant="dark"><small>Impreso {{ item.impreso ? 'SI' : 'NO' }}</small></b-badge>
                                        </td>
                                    </tr>
                                </table>  
                            </b-list-group-item>
                        </b-list-group>

                        <div v-if="loading" class="text-center">Cargando más...</div>
                    </vue-perfect-scrollbar>
                </b-tab>
            </b-tabs>            
        </b-card>        

        <!-- Modal Chofer -->
        <b-modal ref="infoModalRef" id="modalUpdateChofer" title="Cambiar Chofer/Unidad" no-close-on-backdrop hide-footer
            @cancel="isActiveModalChofer = false" @hidden="closeModalChofer" :visible="isActiveModalChofer" size="md">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Chofer" label-for="chofer" label-class="font-weight-bold">
                        <v-select
                            id="chofer"
                            v-model="newChofer"
                            :options="operators" 
                            :reduce="option => option.id"
                            label="label"
                            :clearable="false"
                            :searchable="false"
                            class="select-size-sm"
                            placeholder="Selecciona una opción"
                            @input="selectOperator">
                        </v-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="Camion" label-for="truck" label-class="font-weight-bold">
                        <v-select     
                            :disabled="newChofer == 1"                       
                            id="truck"
                            v-model="newCamion"
                            :options="trucks"
                            :reduce="option => option.id"
                            label="label"
                            :clearable="false"
                            :searchable="false"
                            class="select-size-sm"
                            placeholder="Selecciona una opción">
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="text-right mt-2">                
                <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                    class="mr-2" variant="outline-secondary" @click="closeModalChofer">
                    Cancelar
                </b-button>

                <b-button size="sm" :disabled="loading" @click="assignTruck" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    type="button">
                    Guardar
                </b-button>
            </div>
        </b-modal>

    </b-overlay>
</template>        

<script>
import { debounce } from 'lodash';
import BCardCode from '@core/components/b-card-code'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
    BRow, BCol, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, 
    BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
    BBadge, BListGroup, BListGroupItem, BTab, BTabs, VBPopover, BPopover
} from 'bootstrap-vue'
import store from '@/store'
import router from "@/router";
import vSelect from "vue-select";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from 'vee-validate/dist/rules';
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import EventBus from "@/event-bus"
import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import CollectorsProvider from '@/providers/Collectors'
import WebOperadoresProvider from '@/providers/WebOperadores';
import WebCamionesProvider from '@/providers/WebCamiones';
import DeliveryDocument from '@/@core/components/app-rountings/DeliveryDocument.vue'
import FeatherIcon from '../feather-icon/FeatherIcon.vue';

const DeliveryRoutesResource = new DeliveryRoutesProvider();
const CollectosResource = new CollectorsProvider()
const WebOperadoresResource = new WebOperadoresProvider();
const WebCamionesResource = new WebCamionesProvider();

export default {
    name: 'InfoRoutingInvoice',
    directives: {
        Ripple,
        'b-popover': VBPopover,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BTab, 
        BTabs,
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BBadge,
        BListGroup, BListGroupItem,
        FeatherIcon,
        BCardActions,
        VBPopover, 
        BPopover,

        //
        VuePerfectScrollbar,
        vSelect,
        DeliveryDocument,
    },
    mixins: [heightTransition],
    props: {
        info: {
            type: Object,
            default: () => {},
            required: false,
        },
        isAll: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isOutput: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        showDetail: {
            type: Boolean,
            default: () => false,
            required: false,        
        }
    },
    data () {
        return {
            isMobile: false,
            isLoading: false,
        }    
    },
    computed: {
        isAdministrative () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleAdministrative'] : false
        },
        isViewRouting() {
            return router.currentRoute.name == 'apps-routings-outputs'
        },
        isAutorizator () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == "9da2306d-48fc-4359-af70-27fb9eeb5440" : false
        },
        isAuditor () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == "d5b8906f-b8a3-4072-b18e-5a96757640a0" : false
        },
        isManager() { //Gerente
            return store.getters['auth/getUser'].customRol.id == "7714b05a-aa32-4b9c-9ca1-83036bac0b5d"
        },
        isYard() { // Es patio
            return store.getters['auth/getUser'].customRol.id == "5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0"
        },
        isRouting() { //Cajera | Super Cajera
            return store.getters['auth/getUser'].customRol.id == '6c72a0d5-7a67-43f4-81ff-1ec274c9a10b' ||
                store.getters['auth/getUser'].customRol.id == '01ab07a3-50bf-483c-9fb0-b21c67b1b570'
        },
        hasRouting() {
            return Object.keys(this.routing).length > 0;
        },
        isPaid() {
            return this.routing.dcoto.doctosPVSaldo[0].saldo_Cargo
        },
        canClosed() { // 47 => Permisos para poder cerrar una factura
            return !this.loading && this.routing.estatus !== 'CERRADA' && this.routing.estatus == 'EN RUTA' && this.canAccess(47);
        },
        isPar() {
            return true
        },
        isCustomerSignature() {
            return true
        },
        variantInstruction() {
            if (this.documentVencimiento.estatus == 'PAGADA'){
                return 'success'
            }
            if (this.documentVencimiento.estatus == 'P.A.R'){
                return 'danger'
            }
            if (this.documentVencimiento.estatus == 'CLIENTE FIRMA'){
                return 'warning'
            }
        },       
        diasPlazoLabel() {
            if (this.routing.docto && this.routing.docto.doctosPVSaldo && this.routing.docto.doctosPVSaldo.length > 0 && this.routing.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'PAGADA'
            }

            if (this.routing.cliente.dias_Plazo == 0) {
                return 'P.A.R'
            }
            if (this.routing.cliente.dias_Plazo > 0) {
                return 'CLIENTE FIRMA'
            }
        },
        diasPlazoVariant() {
            if (this.routing.docto && this.routing.docto.doctosPVSaldo && this.routing.docto.doctosPVSaldo.length > 0 && this.routing.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'success'
            }

            if (this.routing.cliente.dias_Plazo == 0) {
                return 'danger'
            }

            if (this.routing.cliente.dias_Plazo > 0) {
                return 'warning'
            }
        },
        filterInRoutings() {
            return this.searchText ? this.routingVisibleItems
                .filter(item => item.folio?.toLowerCase().includes(this.searchText.toLowerCase()) || 
                item.nombre_Cliente?.toLowerCase().includes(this.searchText.toLowerCase())) : this.routingVisibleItems
        },
    },
    created() {
        EventBus.$on('realodRoutings2', async (e) => {
            console.log('Recargando rutas de entrega desde el evento realodRoutings')
            if (e && !this.isViewRouting) {
                await this.getQuantitiesByStatus()
                if (this.activeTab == 0) {
                    await this.getDeliveryRountingByUser('ABIERTA')
                } else if(this.activeTab == 1) {
                    await this.getDeliveryRountingByUserAndStatus('EN RUTA')
                } else if(this.activeTab == 2) {
                    await this.getDeliveryRountingByUserAndPassBy('CLIENTE PASA')
                }
            }
        })        
        EventBus.$on('updateWarehouse', async (almacen) => {
            console.log('Recargando rutas de entrega desde el evento updateWarehouse: ' + almacen)

            await this.getQuantitiesByStatus()
            if (this.activeTab == 0) {
                this.openRoutes = []
                this.visibleItems = []
                this.currentPage = 1
                this.morePagesAvailable = true

                this.routesWithinRoutes = []
                this.routingVisibleItems = []
                this.routingCurrentPage = 1
                this.routingMorePagesAvailable = true

                await this.getDeliveryRountingByUser('ABIERTA')
            } else {
                this.routesWithinRoutes = []
                this.routingVisibleItems = []
                this.routingCurrentPage = 1
                this.routingMorePagesAvailable = true

                this.openRoutes = []
                this.visibleItems = []
                this.currentPage = 1
                this.morePagesAvailable = true

                await this.getDeliveryRountingByUserAndStatus('EN RUTA')
            }
        });
        
    },
    beforeDestroy() {
        // Desuscribirse del evento cuando el componente se destruye
        EventBus.$off('realodRoutings', this.handleReloadRoutings);
        EventBus.$off('updateWarehouse', this.handleReloadRoutings);
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        await this.getTrucks()
        await this.getOperators()
        if (this.isAll && !this.loading && !this.isViewRouting) {
            await this.getDeliveryRountingByUser()
            await this.getQuantitiesByStatus()
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        hidePopover() {
            if (this.isAll && !this.isOutput) {
                this.$root.$emit('bv::hide::popover', 'popover-button-help')
            }
        },
        clearSearchItems() {
            this.isActiveSearch = false
            this.searchText = ''
            this.searchItems = []
            this.searchVisibleItems = []
            this.searchCurrentPage = 1
            this.searchMorePagesAvailable = true
            this.searchTotalPages = 0

        },
        async search() {
            if (this.loading || this.searchMorePagesAvailable.value) return; // No cargar si ya está en proceso o no hay más páginas
            const almacen_Id = store.getters['auth/getWarehouseId'] || 0
            let cantidad = this.totalInRoute
            this.isActiveSearch = true
            
            const query = {             
                fechaInicio: null,
                fechaFin: null,
                numeroPagina: 1, //this.searchCurrentPage,
                tamanoPagina: 100, //this.searchItemsPerPage,
                search: this.searchText,
                almacenId: almacen_Id,
                status: this.isRoutingSearch ? 'EN RUTA' : 'ABIERTA, EN RUTA, CLIENTE PASA, CERRADA, CANCELADA',
                soloClientes: true,
                noImpresos: this.isPrinted,
            }
           
            // if (this.searchTotalPages > 0 && this.searchCurrentPage > this.searchTotalPages) {
            //     this.searchMorePagesAvailable = false;
            //     return;
            // }
           

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query) //.getAllByUserId(null, status, almacen_Id, cantidad)
                if (data.isSuccesful) {
                    if (data.data.length === 0) {
                        this.searchMorePagesAvailable = false; // No hay más datos
                    } else {   
                        // this.searchTotalPages = data.totalPages                         
                        // this.searchItems = [...this.searchItems, ...data.data]
                        // this.searchVisibleItems = this.searchItems.slice(0, this.searchCurrentPage * this.searchItemsPerPage);
                        this.searchVisibleItems = data.data
                        // this.searchCurrentPage++;
                    }                    
                }
            }catch(e) {
                this.loading = false   
            }
            finally {
                this.loading = false
            }
        },

        // Función debounce para manejar la búsqueda
        debouncedSearch: debounce(function () {
            this.search();
        }, 300),
        async scrollHandle(evt) {
            //await this.getDeliveryRountingByUserAndStatus('EN RUTA')
        },
        async copyToClipboard(item) {
            if (item.estatus == 'EN RUTA') {
                try {
                    await navigator.clipboard.writeText(item.folio);                
                } catch (err) {
                    console.error('Error al copiar al portapapeles:', err);
                }
            } else {            
                const formattedMessage = `Hola, necesito solicitar la asignación de un chofer para esta factura. Por favor, tómalo en cuenta.%0A\n%0A\n` +
                    `*Factura:* ${encodeURI(item.folio)}%0A\n` +
                    `*Estatus:* ${encodeURI(item.estatus)}%0A\n` +
                    `*Cliente:* ${encodeURI(item.nombre_Cliente)}%0A\n` +
                    `*Fecha de enrutamiento:* ${encodeURI(this.formatDate(item.fecha_Alta))}%0A%0A\n\n`;

                // Abre WhatsApp con el mensaje formateado
                window.open(`https://wa.me/?text=${formattedMessage}`, '_blank');
            }
        },
        async changeActiveTab(status = 'ABIERTA') {
            if (status == 'ABIERTA') {
                if (this.totalOpening > 0) {
                    await this.getDeliveryRountingByUser(status)
                }
            } else if(status == 'EN RUTA') {
                if (this.totalInRoute > 0) {
                    await this.getDeliveryRountingByUserAndStatus(status)
                }
            } else {
                if (this.totalCustomerPassBy > 0) {
                    await this.getDeliveryRountingByUserAndPassBy(status)
                }
            }
        },
        async handleReloadRoutings(value) {
            if (!this.isOutput) {

                if (!this.isLoading) { // Solo haz la petición si no está cargando
                    this.isLoading = true; // Marca que la petición está en curso
                    console.log('Recargando rutas de entrega desde handleReloadRoutings')

                    if (this.loading) return false
                    try {
                        await this.getDeliveryRountingByUser();
                    } catch (error) {
                    console.error('Error fetching delivery routing data', error);
                    } finally {
                        this.isLoading = false; // Marca que la petición ha terminado
                    }
                }
            }
        },    
        diasPlazoLabelByItem(data) {
            if (data.docto && data.docto.doctosPVSaldo && data.docto.doctosPVSaldo.length > 0 && data.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'PAGADA'
            }

            if (data.cliente.dias_Plazo == 0) {
                return 'P.A.R'
            }
            if (data.cliente.dias_Plazo > 0) {
                return 'CLIENTE FIRMA'
            }
        },
        diasPlazoVariantByItem(data) {
            if (data.docto && data.docto.doctosPVSaldo && data.docto.doctosPVSaldo.length > 0 && data.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'success'
            }

            if (data.cliente.dias_Plazo == 0) {
                return 'danger'
            }

            if (data.cliente.dias_Plazo > 0) {
                return 'warning'
            }
        },
        itemVariantInstruction(item) {
            if (item.estatus == 'PAGADA'){
                return 'success'
            }
            if (item.estatus == 'P.A.R'){
                return 'danger'
            }
            if (item.estatus == 'CLIENTE FIRMA'){
                return 'warning'
            }
        },
        selectOperator(evt) {
            if (evt === 1) {
                //const camion = this.trucks.find(item => item.id == 1)
                this.newCamion = 1
            } else {
                this.newCamion = null
            }
        },
        handlePdfProgress(evt) {            
            this.loading = true
            if (evt >= 100) {
                this.loading = false
            }
        },
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        }, 
        closeModalChofer() {
            this.isActiveModalChofer = false
        },
        async refreshInRoutings () {
            this.routesWithinRoutes = []
            this.routingVisibleItems = []
            this.routingCurrentPage = 1
            this.routingItemsPerPage = 10            
            await this.getQuantitiesByStatus()
            await this.getDeliveryRountingByUserAndStatus('EN RUTA')
        },
        async assignTruck() {

            if (!this.newChofer || !this.newCamion) {
                this.danger('Debes seleccionar un chofer y/o una unidad para continuar')
                return false
            }

            //Buscamos los datos del chofer por el nuevo seleccionado
            const operator = this.operators.find(item => item.id == this.newChofer);

            //Buscamos los datos del camion por el nuevo seleccionado
            const truck = this.trucks.find(item => item.id == this.newCamion);

            this.routing.rutasEntregasDetalles.forEach(element => {
                //element.id = 0
                element.chofer_Id = this.newChofer
                element.nombre_Chofer = operator ? `${operator.nombre} ${operator.apellidos}` : null
                element.camion_Id = this.newCamion
                element.nombre_Camion = truck ? `${truck.identificador} - ${truck.placas}` : null
                //element.unidades = 0
            });

            const payload = {...this.routing}
            
            //Reasignamos el chofer y camion
            payload.chofer_Id = this.newChofer
            payload.camion_Id = this.newCamion
            payload.isPending = false

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.update(payload)
                this.loading = false
                if(data.isSuccesful) {
                    this.isActiveModalChofer = false
                    this.success(`Chofer unidad actualizada con éxito`)
                    
                    this.$nextTick(() => {
                        this.routing = {...data.data}
                        EventBus.$emit('changeOperator', this.routing.docto_Pv_Id)
                    })                    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async closingInvoice() {


            // console.log(this.info)
            // return false
            this.routing.articles = this.info.articles

            const payload = {...this.routing, estatus: 'CERRADA'}
            const isPending = this.routing.articles.reduce((carry, item) => {
                return carry + item.pendiente;                 
            }, 0) > 0

            let message = `¿Está seguro de que desea cerrar la factura <b>${this.routing.folio}</b>? <br><br><p>Esta acción no se puede revertir.</p>`
            if (isPending) {
                // message = `La factura <b>${this.routing.folio}</b> tiene artículos pendientes por entregar. <br> ¿Está seguro de que desea cerrarla y darle salida a los pendientes? <br><br><p>Esta acción no se puede revertir.</p>`
                message = `La factura <b>${this.routing.folio}</b> tiene artículos pendientes por entregar. <br> Elige una opción para continuar <br><br><p>Esta acción no se puede revertir.</p>`
            }

            this.$swal.fire({
                title: `Cerrar Factura`,
                html: message,
                icon: 'question',
                showCancelButton: true,
                showDenyButton: isPending,
                confirmButtonText: isPending ? 'Cerrar Con Pendientes' : 'Continuar',
                denyButtonText: 'Cerrar y Generar Salidas',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: isPending ? "btn btn-block btn-primary" : "btn btn-primary",
                    denyButton: "btn btn-block btn-warning mt-1",
                    cancelButton: isPending ? "btn btn-block btn-outline-danger mt-1" : "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    console.log('is confirm')
                    payload.isPending = false                    
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.update(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.routing = {}
                            this.success(`La factura ha sido cerrada con éxito`)
                            EventBus.$emit('closingInvoice', data.data)
                            await this.refreshInRoutings()

                            // //Eliminar el itema de la lista de rutas EN RUTA
                            // const index = this.routingVisibleItems.findIndex(item => item.docto_Pv_Id == data.data.docto_Pv_Id)
                            // if (index > -1) {
                            //     this.routingVisibleItems.splice(index, 1)
                            // }

                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                preDeny: async () => {
                    console.log('is deny')
                    payload.isPending = true                    
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.update(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.routing = {}
                            this.success(`La factura ha sido cerrada con éxito`)
                            EventBus.$emit('closingInvoice', data.data)
                            await this.refreshInRoutings()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const closedRoutes = ref([])
        const openRoutes = ref([])
        const routesWithinRoutes = ref([])
        const passByRoutes = ref([])    
        const isActiveModalChofer = ref(false)
        const operators = ref([])
        const trucks = ref([])
        const newChofer = ref('')
        const newCamion = ref('')
        const routing = ref(null)
        const documentVencimiento = ref(null)
        const totalOpening = ref(0)
        const totalInRoute = ref(0)
        const totalClosed  = ref(0)
        const totalCustomerPassBy = ref(0)
        const activeTab = ref(0)
        const searchText = ref('')
        const searchItems = ref([])
        const isActiveSearch = ref(false)
        const isPrinted = ref(false)
        const isRoutingSearch = ref(false)

        //Paginate open routes
        const visibleItems = ref([]) // Items visibles en la lista
        const itemsPerPage = ref(10) // Número de items por carga
        const currentPage = ref(1) // Página actual
        const morePagesAvailable = ref(true); // Controla si hay más páginas disponibles
        const totalPages = ref(0) // Total de filas  

        //Paginate routes within routes
        const routingVisibleItems = ref([]) // Items visibles en la lista
        const routingItemsPerPage = ref(10) // Número de items por carga
        const routingCurrentPage = ref(1) // Página actual
        const routingMorePagesAvailable = ref(true); // Controla si hay más páginas disponibles
        const routingTotalPages = ref(0) // Total de filas  

        //Paginate routes within routes
        const passByVisibleItems = ref([]) // Items visibles en la lista
        const passByItemsPerPage = ref(10) // Número de items por carga
        const passByCurrentPage = ref(1) // Página actual
        const passByMorePagesAvailable = ref(true); // Controla si hay más páginas disponibles
        const passByTotalPages = ref(0) // Total de filas  

        //Paginate on search items
        const searchVisibleItems = ref([]) // Items visibles en la lista
        const searchItemsPerPage = ref(10) // Número de items por carga
        const searchCurrentPage = ref(1) // Página actual
        const searchMorePagesAvailable = ref(true); // Controla si hay más páginas disponibles
        const searchTotalPages = ref(0) // Total de filas  

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        const getTrucks = async () => {
            if (trucks.value.length <= 0) {
                loading.value = true
                const { data } = await WebCamionesResource.index({})
                loading.value = false
                if (data.isSuccesful) {
                    trucks.value = data.data.map(item => ({...item, label: `${item.identificador} - ${item.placas}`})).filter(item => item.activo)
                }
            }
        }

        const getOperators = async () => {
            if (operators.value.length <= 0) {
                loading.value = true
                const { data } = await WebOperadoresResource.index({})
                loading.value = false
                if (data.isSuccesful) {
                    operators.value = data.data.map(item => ({...item, label: `${item.nombre} ${item.apellidos}`})).filter(item => item.activo && item.tipo == 'Chofer')
                }
            }
        }

        const getQuantitiesByStatus = async () => {
            const almacen_Id = store.getters['auth/getWarehouseId'] || 0
            const { data } = await DeliveryRoutesResource.getQuantitiesByStatus(almacen_Id, isPrinted.value)
            if (data.isSuccesful) {
                const opening = data.data.find(item => item.estatus == 'ABIERTA')
                const inRoute = data.data.find(item => item.estatus == 'EN RUTA')
                const closed  = data.data.find(item => item.estatus == 'CERRADA')
                const passBy = data.data.find(item => item.estatus == 'CLIENTE PASA')
                totalOpening.value = opening ? opening.cantidad : 0
                totalInRoute.value = inRoute ? inRoute.cantidad : 0
                totalClosed.value = closed ? closed.cantidad : 0
                totalCustomerPassBy.value = passBy ? passBy.cantidad : 0
            }
        }

        const selectItem = (item) => {
            emit('selectRouting', {
                docto_pv_Id: item.docto_Pv_Id,
                estatus: item.estatus
            })
        }

        const getDeliveryRountingByUser = async (status = "ABIERTA") => {
            const almacen_Id = store.getters['auth/getWarehouseId'] || 0
            let cantidad = totalOpening.value;

            if (status == 'ABIERTA' && activeTab.value == 0) {
                if(openRoutes.value.length <= 0) {
                    cantidad = 0
                }
            }

            if (totalOpening.value === 0) {
                // morePagesAvailable.value = false;
                return;
            }

            
            if (loading.value || !morePagesAvailable.value) return; // No cargar si ya está en proceso o no hay más páginas
            
            const query = {             
                fechaInicio: null,
                fechaFin: null,
                numeroPagina: currentPage.value,
                tamanoPagina: itemsPerPage.value,
                search: '',  
                almacenId: almacen_Id,
                status: status
            }

            if (status == "ABIERTA" && activeTab.value == 0) {
                if (totalPages.value > 0 && currentPage.value > totalPages.value) {
                    morePagesAvailable.value = false;
                    return;
                }
            }

            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query) //.getAllByUserId(null, status, almacen_Id, cantidad)
                if (data.isSuccesful) {
                    if (status == 'ABIERTA' && activeTab.value == 0) {
                        if (data.data.length === 0) {
                            morePagesAvailable.value = false; // No hay más datos
                        } else {   
                            totalPages.value = data.totalPages                         
                            openRoutes.value = [...openRoutes.value, ...data.data]
                            visibleItems.value = openRoutes.value.slice(0, currentPage.value * itemsPerPage.value);
                            currentPage.value++;
                        }
                    }
                }
            }catch(e) {
                loading.value = false   
                console.error('Error al obtener datos:', e);
            }
            finally {
                loading.value = false
            }
        }

        const getDeliveryRountingByUserAndStatus = async (status = "EN RUTA") => {
            if (loading.value || !routingMorePagesAvailable.value) return; // No cargar si ya está en proceso o no hay más páginas
            const almacen_Id = store.getters['auth/getWarehouseId'] || 0
            let cantidad = totalInRoute.value

            if (status == 'EN RUTA' && activeTab.value == 1) {
                if (routesWithinRoutes.value.length <= 0) {
                    cantidad = 0
                }
            }           
            
            const query = {             
                fechaInicio: null,
                fechaFin: null,
                numeroPagina: routingCurrentPage.value,
                tamanoPagina: routingItemsPerPage.value,
                search: searchText.value,  
                almacenId: almacen_Id,
                status: status,
                noImpresos: isPrinted.value
            }

           if (activeTab.value == 1) {
                if (routingTotalPages.value > 0 && routingCurrentPage.value > routingTotalPages.value) {
                    routingMorePagesAvailable.value = false;
                    return;
                }
            }

            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query) //.getAllByUserId(null, status, almacen_Id, cantidad)
                if (data.isSuccesful) {
                    if (status == 'EN RUTA' && activeTab.value == 1) {
                        if (data.data.length === 0) {
                            routingMorePagesAvailable.value = false; // No hay más datos
                        } else {   
                            routingTotalPages.value = data.totalPages                         
                            routesWithinRoutes.value = [...routesWithinRoutes.value, ...data.data]
                            routingVisibleItems.value = routesWithinRoutes.value.slice(0, routingCurrentPage.value * routingItemsPerPage.value);
                            routingCurrentPage.value++;
                        }
                    }
                }
            }catch(e) {
                loading.value = false   
            }
            finally {
                loading.value = false
            }
        }

        const getDeliveryRountingByUserAndPassBy = async (status = "CLIENTE PASA") => {
            if (loading.value || !passByMorePagesAvailable.value) return; // No cargar si ya está en proceso o no hay más páginas
            const almacen_Id = store.getters['auth/getWarehouseId'] || 0
            let cantidad = totalCustomerPassBy.value

            if (status == 'CLIENTE PASA' && activeTab.value == 2) {
                if (passByRoutes.value.length <= 0) {
                    cantidad = 0
                }
            }
            
            const query = {             
                fechaInicio: null,
                fechaFin: null,
                numeroPagina: passByCurrentPage.value,
                tamanoPagina: passByItemsPerPage.value,
                search: searchText.value,  
                almacenId: almacen_Id,
                status: status,
                noImpresos: isPrinted.value
            }

           if (activeTab.value == 2) {
                if (passByTotalPages.value > 0 && passByCurrentPage.value > passByTotalPages.value) {
                    passByMorePagesAvailable.value = false;
                    return;
                }
            }

            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query) //.getAllByUserId(null, status, almacen_Id, cantidad)
                if (data.isSuccesful) {
                    if (status == 'CLIENTE PASA' && activeTab.value == 2) {
                        if (data.data.length === 0) {
                            passByMorePagesAvailable.value = false; // No hay más datos
                        } else {   
                            passByTotalPages.value = data.totalPages                         
                            passByRoutes.value = [...passByRoutes.value, ...data.data]
                            passByVisibleItems.value = passByRoutes.value.slice(0, passByCurrentPage.value * passByItemsPerPage.value);
                            passByCurrentPage.value++;
                        }
                    }
                }
            }catch(e) {
                loading.value = false   
            }
            finally {
                loading.value = false
            }
        }
        
        watch(isActiveModalChofer, (value) => {            
            if (value) {
                newChofer.value = routing.value.chofer_Id
                newCamion.value = routing.value.camion_Id
            }
        }) 
        
        watch(isPrinted, async (value) => {
            routesWithinRoutes.value = []
            routingVisibleItems.value = []
            routingCurrentPage.value = 1
            routingItemsPerPage.value = 10
            await getQuantitiesByStatus()
            await getDeliveryRountingByUserAndStatus('EN RUTA')
        })

        watch(props, (delivery) => {   
            routing.value = delivery.info.delivery_rounting
        })

        watch(activeTab, async () => {   
            await getQuantitiesByStatus()
        })

        watch(totalOpening, async (value) => {   
            console.log('totalOpening', value)
            if(value > 0) {
                await getDeliveryRountingByUser()
            } else {
                openRoutes.value = []
                visibleItems.value = []
                currentPage.value = 1
             
            }
        })

        return {
            loading,
            closedRoutes,
            openRoutes,
            routing,
            documentVencimiento,
            isActiveModalChofer,
            operators,
            trucks,
            newChofer,
            newCamion,
            perfectScrollbarSettings,
            activeTab,
            routesWithinRoutes,
            searchText,
            isActiveSearch,
            isPrinted,
            isRoutingSearch,
            
            

            //
            totalOpening,
            totalInRoute,
            totalClosed,
            totalCustomerPassBy,

            //paginate
            visibleItems,
            itemsPerPage,
            currentPage,
            morePagesAvailable,

            routingVisibleItems,
            routingItemsPerPage,
            routingCurrentPage,
            routingMorePagesAvailable,
            routingTotalPages,

            passByVisibleItems,
            passByItemsPerPage,
            passByCurrentPage,
            passByMorePagesAvailable,
            passByTotalPages,

            //Paginate on search items
            searchItems,
            searchVisibleItems,
            searchItemsPerPage,
            searchCurrentPage,
            searchMorePagesAvailable,
            searchTotalPages,

            selectItem,
            getOperators,
            getTrucks,
            getDeliveryRountingByUser,
            getDeliveryRountingByUserAndStatus,
            getQuantitiesByStatus,
            getDeliveryRountingByUserAndPassBy,
        }
    }

}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-email.scss";
.scrollable-container {
  max-height: 400px; /* Ajusta según tus necesidades */
  overflow: auto;
}
</style>